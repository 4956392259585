export const PrimaryButton = (props: {
  children: React.ReactNode
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  className?: string
  id?: string
  ref?: React.Ref<HTMLButtonElement>
}) => {
  return (
    <button
      className={`${props.className} dark:bg-primary-500 dark:hover:bg-primary-400 bg-primary-500 hover:shadow-lg drop-shadow-none hover:brightness-110 text-sm p-2 transition-all disabled:opacity-50 cursor-pointer rounded-xl px-4 text-white`}
      onClick={props.onClick}
      disabled={props.disabled}
      id={props.id}
      ref={props.ref}
    >
      {props.children}
    </button>
  )
}
