import type { NextPage } from 'next'
import { Transition } from '@headlessui/react'
import Head from 'next/head'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import { API_DOMAIN } from '../utils/constants'
import Link from 'next/link'
import { NotifierContext } from '../utils/NotificationsContext'
import UsersListener from '../utils/APIs/DisadusAPI/Listeners/UserListeners'
import { DisadusAPIWrapper, DisadusAPIWrapperBase } from '../utils/APIs/DisadusAPI/DisadusAPIWrapper'
import { getPersistentStore } from '../utils/PersistentStore'
import { observe } from '../utils/nprogress'
import CoursesListener from '../utils/APIs/DisadusAPI/Listeners/CoursesListeners'
import { useCurrentUser } from '../utils/hooks/self/useSelfInfo'
import { User } from '../utils/types/userTypes'
import TextBox from '../components/Inputs/TextBox'
import { AtSymbolIcon, KeyIcon, MailIcon } from '@heroicons/react/outline'
import { PrimaryButton } from '../components/Inputs/Button'
import { fetcher } from '../utils/classes/Fetcher'
import { SelfUserClass } from '../utils/classes/SelfUserClass'

const Login: NextPage = () => {
  const user = useCurrentUser()
  const [animate, setAnimate] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const ignoreLoggedIn = useRef(false)
  const router = useRouter()
  useEffect(() => {
    setAnimate(true)
    if (user) {
      // Notifir.notify({
      //   title: 'Already logged in',
      //   message: 'You are already logged in.',
      //   type: 'error',
      //   duration: 5000,
      // })
      router.push('/app')
    }
  }, [user])

  const resetSignIn = () => {
    setDisabledButton(false)
    setErrorMessage('')
  }
  return (
    <div>
      <Head>
        <title>Disadus - Login</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="icon" href="/logoold.png" />
      </Head>

      <main className={'h-screen flex justify-center items-center'}>
        <div
          className={`w-full h-full absolute top-0 left-0 z-0 dark:filter dark:brightness-50 md:!hidden`}
          style={{ backgroundImage: `url(/kimikoBG.png)`, backgroundSize: 'cover' }}
        />
        {/* Card */}
        <div
          className={`w-screen h-screen flex flex-row relative overflow-hidden justify-end`}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              const button = document.getElementById('login-button')
              if (button) {
                button.click()
              }
            }
          }}
        >
          <div
            className={`w-128 max-w-[90%] dark:bg-gray-800 bg-gray-100 md:!bg-opacity-100 flex-shrink-0 p-8 flex flex-col gap-16 items-start h-full absolute !z-10 xl:!top-1/2 xl:!left-1/2 xl:!-translate-x-1/2 xl:!-translate-y-1/2 xl:h-min xl:rounded-lg xl:gap-4 md:gap-4 md:p-6 shadow-md`}
          >
            <span className={`text-3xl font-medium`}>Login</span>

            <div className={`flex flex-col gap-2 md:gap-0 w-full text-sm items-stretch`}>
              <TextBox
                label={'Email *'}
                value={email}
                onChange={e => setEmail(e.target.value)}
                placeholder={''}
                icon={MailIcon}
                classNamesParent={`w-full`}
                classNamesInput={`w-full`}
                error={!!errorMessage}
              />
              <TextBox
                label={'Password *'}
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder={''}
                icon={KeyIcon}
                type={'password'}
                classNamesParent={`w-full`}
                classNamesInput={`w-full`}
                error={!!errorMessage}
              />
              <span className={`text-red-400`}>{errorMessage}</span>
              <div className={`flex flex-col justify-start mt-8 md:mt-4 gap-2`}>
                <PrimaryButton
                  className={`w-full !p-2`}
                  onClick={() => {
                    setDisabledButton(true)
                    fetcher(`${API_DOMAIN}/login`, {
                      method: 'POST',
                      body: JSON.stringify({
                        email,
                        password,
                      }),
                    })
                      .then(async res => {
                        if (res.status === 200) {
                          const { token } = await res.json()
                          localStorage.setItem('token', token)
                          SelfUserClass.getInstance().fetch()
                        } else {
                          setErrorMessage('Invalid email or password')
                          setDisabledButton(false)
                        }
                      })
                      .catch(() => {
                        setErrorMessage('Invalid email or password')
                        setDisabledButton(false)
                      })
                  }}
                  id={'login-button'}
                >
                  <span className={`font-medium text-lg`}>Login</span>
                </PrimaryButton>
                <span className={`text-gray-700/40 dark:text-gray-500`}>
                  {' '}
                  or <Link href={'/signup'}>Signup</Link> instead
                </span>
              </div>
            </div>
            <span className={`w-full flex flex-row items-center gap-2 pt-8`}>
              <div className={`h-0 flex-grow border dark:border-gray-700/50 rounded-full`} />
              <span className={`dark:text-gray-600`}>Or</span>
              <div className={`h-0 flex-grow border dark:border-gray-700/50 rounded-full`} />
            </span>
            <div className={`flex flex-col gap-3 w-full items-center`}>
              <span className={`dark:text-gray-400`}>External Sign-in Providers</span>
              <div className={` w-full max-h-48 rounded-lg flex flex-row justify-center`}>
                <Link href={`/signup/external/sgy/`}>
                  <div
                    className={`w-12 h-12 p-1 rounded-lg cursor-pointer dark:bg-gray-850 dark:hover:bg-gray-750 bg-gray-150 hover:bg-white hover:shadow-md  transition-all`}
                    onClick={() => {
                      globalThis.sessionStorage.setItem('SGYauthType', 'signup')
                    }}
                  >
                    <img src="/external/lmsIcons/schoology.svg" className={`w-full h-full`} />
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className={`w-full h-full absolute object-cover z-0`}>
            <img src={'/kimikoBG.png'} className={`w-full h-full object-cover object-left-top opacity-60`} />
            <div
              className={`w-full h-full opacity-40 absolute`}
              style={{
                backgroundImage: `radial-gradient(at 40% 20%, hsla(252,100%,74%,1) 0px, transparent 50%),
              radial-gradient(at 80% 0%, hsla(189,100%,56%,1) 0px, transparent 50%),
              radial-gradient(at 50% 42%, hsla(140,100%,78%,1) 0px, transparent 50%),
              radial-gradient(at 80% 50%, hsla(233,100%,76%,1) 0px, transparent 50%),
              radial-gradient(at 14% 90%, hsla(128,100%,77%,1) 0px, transparent 50%),
              radial-gradient(at 80% 100%, hsla(185,100%,70%,1) 0px, transparent 50%),
              radial-gradient(at 0% 0%, hsla(135,100%,76%,1) 0px, transparent 50%)`,
              }}
            />
          </div>
        </div>
      </main>
    </div>
  )
}

export default Login
